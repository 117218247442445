class DependentField{
  constructor(dep, config){
    this.options = config;
    this.target = dep;
    this.target.dataset.dependent = true;

    const props = this.target.dataset.depends.toString();
    const [input, value] = props.split('->');
    

    this.value = value;
    this.input = document.querySelector(input);
    
    const type = this.input.getAttribute('type');
    this.isBool = ['radio', 'checkbox'].includes(type);
    
    this.input.addEventListener('change', this.onChange.bind(this));

    if ("createEvent" in document){
      const evt = document.createEvent("HTMLEvents");
      evt.initEvent("change", false, true);
      this.input.dispatchEvent(evt);
    }else{
      this.input.fireEvent("onchange");
    }

  }

  onChange(event){
    
    if( event.target !== this.input ) return true;
    const klass = this.options.hiddenClass || 'd-none';

    if( this.isBool ){
      const checked = this.input.checked;

      if( checked === true && this.value === 'true' ){
        this.target.classList.remove(klass);
      }else if( checked === false && this.value === 'false' ){
        this.target.classList.remove(klass);
      }else{
        this.target.classList.add(klass);
      }

      return true;
    }

    const current = event.target.value;
    if( current == this.value ){
      this.target.classList.remove(klass);
    }else{
      this.target.classList.add(klass);
    }
  }
}

const DependentFields = {
  config: {
    hiddenClass: 'd-none'
  },
  init: (options = null)=>{
    const config = options || DependentFields.config;
    document.querySelectorAll('[data-depends]').forEach( node => {
      if( node.dataset.depdendent !== undefined ) return true;
      new DependentField(node, config);
    });
  }
}

export default DependentFields;